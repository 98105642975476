import { addCustomerProperties, submitOptIn } from '../../api';
import { parsePhone, promiseTry, track, trackError } from '../../lib';
import { getStoredBrowserIdentifier, getStoredBrowserSessionIdentifier, storeAccountLocally } from '../customer';

import type { JSONObject, TOKEN, TokenType } from '@onetext/api';

type SubmitOptInOptions = {
    accountToken ?: TokenType<TOKEN.ACCOUNT>,
    phone ?: string,
    customFlowParameters ?: JSONObject,
    customerProperties ?: Record<string, string>,
    email ?: string,
    allowRateLimitWelcomeFlow ?: boolean,
    popupToken : TokenType<TOKEN.POPUP>,
};

type CustomerOptInResponse = {
    accountToken : TokenType<TOKEN.ACCOUNT> | undefined,
};

export const submitCustomerOptIn = async ({
    accountToken,
    phone: phoneCandidate,
    email,
    customerProperties,
    customFlowParameters,
    allowRateLimitWelcomeFlow = true,
    popupToken
} : SubmitOptInOptions) : Promise<CustomerOptInResponse> => {
    const phone = phoneCandidate
        ? parsePhone(phoneCandidate)
        : undefined;

    if (phoneCandidate && !phone) {
        track('optin_submit_invalid_phone', {
            phoneCandidate
        });
    }

    /**
     * For speed, we always create a customer profile using this protocol
     * to take advantage of our Klaviyo profile de-duplicating logic at
     * /customer/properties/set endpoint, even with no quiz properties
     */

    const customerPropertiesPromise = promiseTry(() => {
        if (accountToken || email || phone) {
            return addCustomerProperties({
                accountToken,
                phone,
                email,
                properties: customerProperties ?? {},
                popupToken
            });
        }
    }).catch(err => {
        trackError(err, {
            type:      'optin_submit_customer_properties',
            hardError: false
        });
    });

    const submitOptInPromise = promiseTry(async () => {
        if (phone || email) {
            await customerPropertiesPromise;

            return await submitOptIn({
                accountToken,
                phone,
                email,
                customFlowParameters,
                requestTwoFactorCode:             false,
                allowWelcomeFlow:                 true,
                allowRateLimitWelcomeFlow,
                popupToken,
                customerBrowserIdentifier:        getStoredBrowserIdentifier(),
                customerBrowserSessionIdentifier: getStoredBrowserSessionIdentifier()
            });
        }
    });

    const [ optInResult ] = await Promise.all([
        submitOptInPromise,
        customerPropertiesPromise
    ]);

    if (optInResult?.body.accountToken) {
        storeAccountLocally(optInResult.body.accountToken);

        return {
            accountToken: optInResult.body.accountToken
        };
    }

    return {
        accountToken
    };
};
