/** @jsx h */

import { h } from 'preact';

import type { POPUP_VIEWPORT_MODE } from '../../../constants';

import {
    ConsentPopup as Popup, ConsentPopupBackground as Background, ConsentPopupContainer as PopupContainer,
    ConsentPopupTeaser as Teaser
} from '../components';

import { TemplatePage } from './components';

import type { GetPopupAPIResponse, Milliseconds } from '@onetext/api';
import { COUNTRY } from '@onetext/api';

type PopupTemplateProps = {
    popup : GetPopupAPIResponse,
    popupViewportMode ?: POPUP_VIEWPORT_MODE,
};

export const PopupTemplate = ({ popup, popupViewportMode } : PopupTemplateProps) : JSX.Element | null => {

    const config = popup.config;
    const teaser = config?.component?.teaser;

    return (
        <PopupContainer
            popup={ { ...popup, config } }
            delay={ config?.behavior?.delay?.popup?.milliseconds as Milliseconds }
            defaultPopupViewportMode={ popupViewportMode }
            shownToCountries={
                popup.config?.component?.terms?.brandName === 'Raycon'
                    ? [ COUNTRY.US ]
                    : []
            }
        >
            <Popup
                layout={ config?.style?.layout }
                background={ <Background overrideStyles={ config?.template?.background?.style } /> }
            >
                {
                    config?.template?.pages.map((page) => (
                        <TemplatePage page={ page } />
                    ))
                }
            </Popup>
            {
                teaser?.enabled
                    ? (
                        <Teaser
                            customText={ teaser.content }
                        />
                    )
                    : null
            }
        </PopupContainer>
    );
};
